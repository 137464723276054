import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { navItems } from '../../_nav';
import { HeaderService } from '../header/header.service';

@Component({
  selector: 'ss-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, AfterViewInit {
  public navItems = navItems;
  public isReduced = false;
  public isOpen = false;
  public permissionList: string[] = [];
  public currentSubmenu = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private headerService: HeaderService) { }

  ngOnInit() {
    this.headerService.getUserData().subscribe((response: any) => {
      this.permissionList = response.menu_permissions.map((perm: any) => perm.toUpperCase().trim());
    });

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        let x = event.url.split('/');
        this.currentSubmenu = x[2] + '/' + x[3];
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      let x = this.router.url.split('/');
      this.currentSubmenu = x[2] + '/' + x[3];
    }, 500);
  }

  showMenuItem(item: any): boolean {
    if (item.children) {
      const hasPermission = item.children.some((child: any) => {
        const permissionCheck = this.permissionList.includes(child.permission.toUpperCase().trim());
        return permissionCheck;
      });

      return hasPermission;
    }
    return false;
  }
}
