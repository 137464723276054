import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
// third party
import { ToastrModule } from 'ngx-toastr';
import { CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { CustomCurrencyMaskConfig } from './shared/components-config/currency-mask/custom-currency-mask-config';
// first party
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { RequestInterceptor } from './core/auth/request.interceptor';
import { CustomEncodeHttpParamsInterceptor } from './shared/components-config/custom-encode-http-params';
import { DateInterceptorService } from './shared/data-interceptor/data-interceptor.service';
import { DashboardModule } from './main/dashboard/dashboard.module';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSmartModalModule } from 'ngx-smart-modal';

registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      progressBar: true,
      progressAnimation: 'increasing',
      timeOut: 3000
    }),
    AppRoutingModule,
    CoreModule,
    DashboardModule,
    NgxSmartModalModule.forRoot(),
  ],
  exports: [],
  providers: [
    NgbModal,
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    { provide: HTTP_INTERCEPTORS, useClass: CustomEncodeHttpParamsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DateInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
