import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../shared/constants.service';

@Injectable({ providedIn: 'root' })
export class HeaderService {
  baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = AppConstants.baseUrl;
  }

  getNotifications(params: any) {
    // params['per_page'] = 3;
    return this.http.get(this.baseUrl + '/notifications', { params });
  }

  readNotification(id: number) {
    const url = `${this.baseUrl}/notifications/${id}/read-notification`;
    return this.http.put(url, {});
  }

  getTotalUnreadNotification() {
    return this.http.get(this.baseUrl + '/notifications/total-unread', {});
  }

  readAllNotifications() {
    return this.http.put(this.baseUrl + '/notifications/read-all-notifications', {});
  }

  getUserData() {
    return this.http.get(this.baseUrl + '/users/me');
  }
}
