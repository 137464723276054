export const navItems = [
  {
    name: 'Comercial',
    icon: 'icon-case',
    children: [
      {
        permission: 'CLIENT',
        name: 'Clientes',
        url: 'commercial/clients',
        icon: 'icon-order-person'
      },
      {
        permission: 'BANK',
        name: 'Bancos',
        url: 'commercial/banks',
        icon: 'icon-bank'
      },
      {
        permission: 'CLIENT',
        name: 'Representantes',
        url: 'commercial/representatives',
        icon: 'icon-adult'
      },
      // {
      //   permission: 'ORDER',
      //   name: 'Orçamentos',
      //   url: 'commercial/orders',
      //   icon: 'icon-ticket'
      // },
      // {
      //   permission: 'BUDGET',
      //   name: 'Pedidos',
      //   url: 'commercial/budgets',
      //   icon: 'icon-bill'
      // },
      // {
      //   permission: 'ITEM',
      //   name: 'Produtos e Serviços',
      //   url: 'commercial/items',
      //   icon: 'icon-box'
      // },
      // {
      //   permission: 'REASON',
      //   name: 'Motivos',
      //   url: 'commercial/reasons',
      //   icon: 'icon-note'
      // }
    ]
  }, {
    name: 'Operacional',
    icon: 'icon-operational',
    children: [
      {
        permission: 'MASTEREVENT',
        name: 'Grupos de Eventos',
        url: 'operational/events-master',
        icon: 'icon-flag'
      }, {
        permission: 'EVENT',
        name: 'Eventos',
        url: 'operational/events',
        icon: 'icon-flag'
      }, {
        permission: 'TECHNICIAN',
        name: 'Técnicos',
        url: 'operational/technicians',
        icon: 'icon-table-bell'
      }, {
        permission: 'PRODUCT_CATEGORY',
        name: 'Categorias de Produtos',
        url: 'operational/products-categories',
        icon: 'icon-th-thumb'
      }, {
        permission: 'PRODUCT',
        name: 'Produtos',
        url: 'operational/products',
        icon: 'icon-hamburger'
      }, {
        permission: 'ADDITIONALITEM',
        name: 'Adicionais de Produto',
        url: 'operational/additionalitem',
        icon: 'icon-bill'
      }, {
        permission: 'PRINTERDEVICE',
        name: 'Impressoras',
        url: 'operational/printers',
        icon: 'icon-reprint'
      }, {
        permission: 'POINT_OF_SALE',
        name: 'Pontos de Venda',
        url: 'operational/point-of-sales',
        icon: 'icon-money-pin'
      }, {
        permission: 'PAYMENT_TYPE',
        name: 'Formas de Pagamento',
        url: 'operational/payment-types',
        icon: 'icon-credit-card'
      }, {
        permission: 'DEVICE',
        name: 'Dispositivos',
        url: 'operational/devices',
        icon: 'icon-phone'
      }, {
        permission: 'APPLICATION_ROLE',
        name: 'Permissões do App',
        url: 'operational/app-permissions',
        icon: 'icon-phone-checked'
      }, {
        permission: 'NCM',
        name: 'NCM',
        url: 'operational/ncms',
        icon: 'icon-phone-checked'
      }
    ]
  }, {
    name: 'Administrativo',
    icon: 'icon-tools',
    children: [
      {
        permission: 'ROLE',
        name: 'Permissões',
        url: 'administrative/permissions',
        icon: 'icon-permissions'
      }, {
        permission: 'USER',
        name: 'Usuários',
        url: 'administrative/users',
        icon: 'icon-usuario'
      }, {
        permission: 'PENDENCY',
        name: 'Pendências',
        url: 'administrative/pendencies',
        icon: 'icon-warning'
      }, {
        permission: 'NOTIFICATION',
        name: 'Notificações',
        url: 'administrative/notifications',
        icon: 'icon-bell'
      }, {
        permission: 'ORGANIZATION',
        name: 'Organizações',
        url: 'administrative/organizations',
        icon: 'icon-organizations'
      }, {
        permission: 'PUBLICACCESSTOKEN',
        name: 'Acessos API',
        url: 'administrative/api-access',
        icon: 'icon-cog'
      }, {
        permission: 'ORGANIZATIONPARTNER',
        name: 'Parceiros',
        url: 'administrative/partners',
        icon: 'icon-people'
      }
    ]
  }, {
    name: 'Financeiro',
    icon: 'icon-dollar',
    children: [
      {
        permission: 'ACQUIRERFEE',
        name: 'Cadastro de Taxas',
        url: 'financial/fees',
        icon: 'icon-chart-bar'
      }, {
        permission: 'EVENTCLOSING',
        name: 'Fechamento',
        url: 'commercial/closures',
        icon: 'icon-money-sack'
      }, {
        permission: 'EVENTCLOSINGTAXINFORMATION',
        name: 'Controle Fiscal',
        url: 'financial/tax-informations',
        icon: 'icon-tax'
      }, {
        permission: 'ATTENDEEREFUND',
        name: 'Reembolsos',
        url: 'financial/refunds',
        icon: 'icon-money-pin'
      }, {
        permission: 'CLIENT',
        name: 'Repasses',
        url: 'financial/transfer-control',
        icon: 'icon-loading'
      }, {
        permission: 'CLIENT',
        name: 'Adiantamentos',
        url: 'financial/advances-management',
        icon: 'icon-credit-card'
      }, {
        permission: 'CLIENT',
        name: 'Metas de Faturamento',
        url: 'financial/revenue-goals',
        icon: 'icon-dollar'
      }, {
        permission: 'CLIENT',
        name: 'Transações',
        url: 'financial/account-transactions',
        icon: 'icon-exchange'
      }, {
        permission: 'CLIENT',
        name: 'Dashboard',
        url: 'financial/dashboard',
        icon: 'icon-bars'
      }
    ]
  }, {
    name: 'Bilheteria',
    icon: 'icon-ticket',
    children: [
      {
        permission: 'TICKETOFFICESALEPOINT',
        name: 'Pontos de Venda',
        url: 'ticket-office/ticket-office-point-of-sale',
        icon: 'icon-money-pin'
      }
    ]
  }, {
    name: 'Inventário',
    icon: 'icon-note',
    children: [
      {
        permission: 'DEVICE_RENTALL',
        name: 'Aluguel de Dispositivos',
        url: 'inventory/device-rental',
        icon: 'icon-monitor'
      },
      {
        permission: 'PHYSICAL_DEVICE',
        name: 'Dispositivos Físicos',
        url: 'inventory/physical-devices',
        icon: 'icon-mobile-1'
      },
      {
        permission: 'DEVICE_RENTALL',
        name: 'Dashboard',
        url: 'inventory/dashboard',
        icon: 'icon-bars'
      }
    ]
  }
];