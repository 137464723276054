<aside class="ss-side-menu">
  <div *ngFor="let item of navItems; let last = last;" ssToggleSubmenu>
    <!-- menu -->
    <div class="ss-side-menu__item" [ngClass]="{'no-border': last}" *ngIf="showMenuItem(item)">
      <span class="ss-side-menu__icon" [ngClass]="item.icon"></span>
      <span class="ss-side-menu__text">{{ item.name }}</span>
      <span class="icon-down-open"></span>
    </div>

    <!-- submenu -->
    <div *ngIf="item.children" class="ss-side-submenu">
      <ng-template *ngFor="let subitem of item.children" [ssHasPermission]="subitem.permission" [ssHasPermissionAction]="'index'">
        <a [routerLink]="[subitem.url]" class="ss-side-submenu__item" [ngClass]="{'teste': currentSubmenu == subitem.url}">
          <span class="ss-side-submenu__icon" [ngClass]="subitem.icon"></span>
          <span class="ss-side-submenu__text">{{ subitem.name }}</span>
        </a>
      </ng-template>
    </div>
  </div>

  <div id="shrink-menu" class="ss-side-menu__item ss-side-menu__shrink" ssShrinkMenu>
    <span class="pr-1 icon-arrow-left"></span>
    <span class="pr-1">reduzir</span>
    <span>menu</span>
  </div>
</aside>
