import { Directive, Input, ViewContainerRef, TemplateRef, OnInit } from '@angular/core';

const KEY_P = 'permissions';
const KEY_E = 'entities';

@Directive({
  selector: '[ssHasPermission]'
})
export class HasPermissionDirective implements OnInit {
  @Input('ssHasPermission') entity = '';
  @Input('ssHasPermissionAction') action = '';

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit(): void {
    this.entity = this.entity.replace(/_/g, '').toUpperCase();

    const entities = (window.localStorage.getItem(KEY_E) || '').split(',');
    const actions = JSON.parse(window.localStorage.getItem(KEY_P) || '{}');

    if (entities.includes(this.entity)) {
      if (actions[this.entity] && actions[this.entity].includes(this.action.toLowerCase())) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainerRef.clear();
      }
    } else {
      this.viewContainerRef.clear();
    }
  }
}
