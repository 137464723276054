import { AfterViewInit, Component, Input, OnInit, Renderer2 } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { share, switchMap } from 'rxjs/operators';
import { UserNotification } from '../../../shared/interfaces/userNotification';
import { HeaderService } from '../header.service';

@Component({
  selector: 'ss-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, AfterViewInit {
  @Input() username: string = "";
  isLoading: boolean = false;
  isShown: boolean = false;
  totalUnread$!: Observable<any>;
  notifications$!: Observable<UserNotification[]>;

  notifications: UserNotification[] = [];

  hasUnread: boolean = false;

  private _timeoutNotifications: any;
  private _currentPage = 0;
  private _lastPage = false;

  constructor(
    private headerService: HeaderService,
    private render: Renderer2) { }

  ngOnInit() {
    this.totalUnread$ = this.headerService.getTotalUnreadNotification();

    this.render.listen('document', 'click', (event) => {
      if (event.target.closest('#notifications') || event.target.closest('#notificationList')) { return; }

      if (this.isShown) {
        this.toggleNotifications();
      }
    });
  }

  ngAfterViewInit() {
    this.totalUnread$ = interval(30000).pipe(
      switchMap(() => this.headerService.getTotalUnreadNotification()),
      share()
    );

    setInterval(() => {
      this.getNotifications();
    }, 30000);
  }

  // PUBLIC METHODS
  toggleNotifications(): void {
    this.isShown = !this.isShown;
    this._currentPage == 0 ? this.getNotifications() : '';
  }

  getNotifications(): void {
    this.isLoading = true;
    const params: any = {};
    params['ordering'] = 'created_at:desc';
    params['by_user'] = this.username;
    this.headerService.getNotifications(params).subscribe((response: any) => {
      this.notifications = response.content;
      this.isLoading = false;

      this._currentPage = response.current_page;
      this._lastPage = this._currentPage == response.total_pages;
    }, (error: any) => {
      console.log(error);
    });
  }

  setWasRead(id: any, wasRead: boolean, position: number): void {
    if (wasRead) { return; }

    this.headerService.readNotification(id).subscribe(() => {
      this.notifications[position]['was_read'] = true;
      this.totalUnread$ = this.headerService.getTotalUnreadNotification();
    }, (error: any) => {
      console.log(error);
    });
  }

  setAllread(): void {
    this.headerService.readAllNotifications().subscribe((response: any) => {
      this.notifications.map(notification => notification['was_read'] = true);
      this.totalUnread$ = this.headerService.getTotalUnreadNotification();
    }, (error: any) => {
      console.log(error);
    });
  }

  onScroll(e: any): void {
    if(this._lastPage) return;

    clearTimeout(this._timeoutNotifications);
    this._timeoutNotifications = setTimeout(() => {
      if(e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight){
        const params: any = {};
        params['ordering'] = 'created_at:desc';
        params['by_user'] = this.username;
        params['page'] = this._currentPage + 1;
        this.headerService.getNotifications(params).subscribe((response: any) => {
          this.notifications.push(...response.content);
          this._currentPage = response.current_page;
          this._lastPage = this._currentPage == response.total_pages;
        }, (error: any) => {
          console.log(error);
        });
      }
    }, 500);
  }
}
