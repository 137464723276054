<header class="ss-header">
  <div class="d-md-none d-lg-none d-xl-none">menu<!-- menu icon mobile --></div>
  <a class="ss-header__logo" [routerLink]="['/main/operational/events']"><!-- logo --></a>

  <div class="d-inline-flex">
    <!--<div class="ss-header__health"></div>-->

    <ss-notifications [username]="username"></ss-notifications>

    <div id="userOptions" class="ss-header__user" (click)="toggleUserOptions()">
      <span class="m-2"> Bem vindo(a), {{username}}</span>
      <span class="ss-header__user__photo" [ngStyle]="{'background-image' : 'url(' + userImage + ')'}" [innerHTML]="userInitials"></span>

      <!-- User options -->
      <div *ngIf="!userOptions" class="ss-header__user-menu">
        <!-- <a class="ss-header__user-menu__option"><span class="icon-pencil mr-2"></span>Editar Perfil</a> -->
        <a class="ss-header__user-menu__option" (click)="logout()"><span class="icon-exit mr-2"></span>Sair</a>
      </div>
    </div>
  </div>
</header>
